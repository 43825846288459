@import url('https://fonts.googleapis.com/css?family=Archivo Black');
@import url('https://fonts.googleapis.com/css?family=Josefin+Sans|Oswald&display=swap');
@import url('https://fonts.googleapis.com/css?family=Big+Shoulders+Text&display=swap');


:root {
  --main-bg-color: #2f497c;
  --main-fg-color: #147777;
}

.App {
  text-align: center;
}

.App-logo {
  flex: 1;
  justify-content: center;
  text-align: center;
  background-color: var(--main-bg-color);
  justify-content: center;
}

.App-logo-row {
  max-height: 18vmin;
  justify-content: center;
  vertical-align: middle;
  background-color: var(--main-bg-color);
  text-align: center;
}

.ah-tag-line {
  max-height: 18vmin;
  justify-content: center;
  vertical-align: middle;
  background-color: var(--main-bg-color);
  color: white;
  text-align: center;
}

.App-logo-text { 
  justify-content: center;
  text-align: center;
  background-color: #215bc6;
  justify-content: center;
  font-size: calc(9vmin);
  color: black;
  font-family: 'Archivo Black';
  text-transform: none;
  vertical-align: middle;

}

.MuiToolbar-dense {
  min-height: 0px;
}

.App-header {
  background-color: var(--main-bg-color);
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: left;
  color: white;
}

.App-appbar {
  background-color: var(--main-bg-color);
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
  font-size: calc(25px + 2vmin);
  color: white;
}

.App-button {
  background-color: var(--main-bg-color);
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
  font-size: calc(3px + (2vmin));
  color: white;
}

.page-spacer {
  background-color: var(--main-fg-color);
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
  font-size: calc(3px + (2vmin));
  color: white;
}

.App-toolbar {
  background-color: #147777;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
  font-size: calc(15px + 2vmin);
  color: white;
}

.App-body { 
  background-color: var(--main-bg-color);
  min-height: 80vh;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: left;
  font-size: calc(10px + 2vmin);
  color: rgb(143, 125, 125);
}

.Game-title { 
  background-color: #147777;
  display: flex;
  flex-direction: row;
  justify-content: left;
  font-size: calc(15px + 2vmin);
  color: rgb(0, 0, 0);
  font-family: 'Oswald', sans-serif;
  align-items: center;
}

.Game-short-description { 
  background-color: var(--main-bg-color);
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: left;
  font-size: calc(12px + 2vmin);
  color: white;
  white-space: pre-line;
  font-family: 'Oswald', sans-serif;
}

.Game-long-description { 
  background-color: var(--main-bg-color);
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: left;
  font-size: calc(10px + 2vmin);
  color: white;
  white-space: pre-line;
  font-family: 'Big Shoulders Text', cursive;
}

.Game-ios-sticky { 
  background-color: #147777;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
  vertical-align: middle;
  font-size: calc(10px + 2vmin);
  color: rgb(143, 125, 125);
}

.Game-app-shot { 
  background-color: #147777;
  display: flex;
  width: 400;
  align-items: center;
  flex-direction: row;
  align-items: stretch;
}

.Grid-summary-item { 
  background-color: #147777;
  display: flex;
  width: 400;
  align-items: center;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
}

.screen-shot {
  background-color: #147777;
}

.App-footer {
  background-color: #147777;
  min-height: 10vh;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.App-copyright {
  background-color: #147777;
  min-height: 4vh;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
  font-size: calc(10px);
  color: white;
}

.App-copyright-link {
  color: white;
}

.App-link {
  color: #09d3ac;

}

.App-menu-items {
  background-color: var(--main-bg-color);
  display: flex;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.Logo {
  background-color: var(--main-bg-color);
  display: flex;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}


.App-slideshow {
  background-color: var(--main-bg-color);
  display: flex;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  max-width: 100%;
}



img { max-width: 100%;
   }
